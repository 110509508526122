import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import Preview from './Preview';
import NotFound from './containers/NotFound';
import './stylesheets/bootstrap.css';
import Page from './containers/Page';
import Project from './containers/Project';
import ScrollToTop from './components/ScrollToTop';
import AllArticles from "./components/AllArticles";
import Article from "./containers/Article";


const App = (props) => (
    <Router>
        <ScrollToTop>
            <Switch>
                <Redirect exact from="/" to="/page/homepage"/>
                <Route exact path="/articles" render={routeProps =>
                    <AllArticles {...routeProps}/>}/>
                <Route exact path="/page/:uid" render={routeProps =>
                    <Page {...routeProps}/>}/>
                <Route exact path="/project/:uid" render={routeProps =>
                    <Project key={routeProps.location.key} {...routeProps}/>}/>
                <Route exact path="/article/:uid" render={routeProps =>
                    <Article key={routeProps.location.key} {...routeProps}/>}/>
                <Route exact path="/preview" render={routeProps =>
                    <Preview {...routeProps}/>}/>
                <Route component={NotFound}/>
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
