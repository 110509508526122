import Prismic from 'prismic-javascript'

export const linkResolver = (doc) => {

    if (doc.type === 'page') {
        return `/${doc.uid}`;
    }

    return '/';
};

export const prismicClient = () => {

    const apiEndpoint = process.env.REACT_APP_PRISMIC_URL;
    const accessToken = process.env.REACT_APP_PRISMIC_API_KEY;

    return Prismic.client(apiEndpoint, {accessToken})
};
