import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import Prismic from 'prismic-javascript';
import Loading from './Loading';
import {prismicClient} from "../prismic-configuration";
import ArticleThumb from "./ArticleThumb";


export default class AllArticles extends React.Component {

    state = {articles: []}

    componentDidMount() {
        console.log("test");
        this.fetchAllArticles(this.props);
    }

    componentDidUpdate(props) {
        //this.fetchAllArticles(props);
    }

    fetchAllArticles(props) {
        prismicClient().query(
            Prismic.Predicates.at('document.type', 'article'),
            {pageSize: 100, orderings: '[my.article.priority]'}
        ).then((response) => {
            console.log(response);
            if (response.results.length > 0) {
                this.setState({articles: response.results})
            }
        });
    }

    renderArticle = (article) => (
        <ArticleThumb uid={article.id}/>
    );

    render() {
        if (this.state.articles) {
            return (
                <Container className="content">
                    <Row>
                        <Col xs="12" sm={{size: 6, order: 2, offset: 3}}>
                            {this.state.articles.map(article => this.renderArticle(article))}
                        </Col>
                    </Row>
                </Container>
            );
        }
        return <Loading/>;
    }
}
