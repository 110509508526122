import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navigation from './components/Navigation';
import App from "./App";

ReactDOM.render(
    <div>
        <Navigation/>
        <App/>
    </div>,
    document.getElementById('root'),
);
