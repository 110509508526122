// In src/Article.js

import React from 'react';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import {Col, Container, Row} from 'reactstrap';
import Loading from '../components/Loading';
import {prismicClient} from "../prismic-configuration";


export default class Article extends React.Component {

    state = {
        doc: null,
        notFound: false,
    }

    componentDidMount() {
        this.fetchArticle(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.uid !== prevProps.match.params.uid) {
            this.fetchArticle(prevProps);
        }
    }

    fetchArticle(props) {

        // We are using the function to get a document by its uid
        return prismicClient().getByID(props.match.params.uid, {}, (err, doc) => {
            if (doc) {
                // We put the retrieved content in the state as a doc variable
                this.setState({doc});
            } else {
                // We changed the state to display error not found if no matched doc
                this.setState({notFound: !doc});
            }
        });
    }

    render() {
        if (this.state.doc) {

            const doc = this.state.doc;

            return (
                <div>
                    <Container className="content article">
                        <Row>
                            <Col sm="12" md={{size: 8, offset: 2}} data-wio-id={doc.id}>
                                <h2>{PrismicReact.RichText.asText(doc.data.title)}</h2>
                                <div className="text-muted">
                                    {PrismicReact.RichText.render(doc.data.body, prismicClient().linkResolver)}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>)
        } else if (this.state.notFound) {
            return <NotFound/>;
        }
        return <Loading/>;
    }
}
