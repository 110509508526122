import React from 'react';
import {Container, Jumbotron} from 'reactstrap';

const NotFound = () => (
    <Container className="content">
        <Jumbotron className="text-center">
            Page not found
        </Jumbotron>
    </Container>
);

export default NotFound;
